:root {
    --mainPrimary: #0075BC;
    --mainSecondary: #FF6702;
    --mainInfo: #00B9EB;
    --bgLightBlue: #CFE2F9;
    --bgEntel: #F3F7F9;
    --textGray: #9E9FA1;
    --textDark: #4E5057;
    --textWhite: #fff;
    --bs-body-color: #555555;
    --textDanger: rgb(224, 53, 53);
}

body {
    height: 100%;
    margin: 0;
    color: #555555;

    font-family: 'Open Sans', sans-serif;
}

table{
    font-family: 'Lato'; 
}

input {
    color: #555555;
    accent-color: var(--mainPrimary);
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

div.fade.in.modal {
    display: flex !important;
}

.modal-dialog {
    margin: auto;
    top: 50%;
    transition: transform .3s ease-out;
    transform: translate(0, -50%) !important;
}

.rdt_Pagination,
.dmPvgD {
    color: var(--mainPrimary) !important;
}

.rdt_TableCol div div {
    white-space: normal !important;
}

.hpErMj {
    color: var(--textGray) !important;
}

.gJSYlG {
    border: 1px solid var(--mainPrimary) !important;
    border-radius: 2px;
}

.no-border {
    border: none !important;
    height: auto !important;
}

.text-muted-2 {
    color: var(--textGray)
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-button {
    width: 8px;
    height: 5px;
}

::-webkit-scrollbar-track {
    background: #eee;
    border: thin solid lightgray;
    box-shadow: 0px 0px 3px #dfdfdf inset;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: rgb(185, 185, 185);
    border: thin solid rgb(182, 175, 175);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #b4aeae;
}


::-webkit-scrollbar:horizontal {
    height: 8px;
}


@media only screen and (min-width: 992px) {
    .w-lg-auto {
        width: auto !important;
    }
}

.modal-text {
    white-space: break-spaces;
}

a {
    text-decoration: none
}

@media screen and (max-width: 576px) {
    .text-sm-nowrap {
        white-space: nowrap;
    }
}

.grecaptcha-badge {
    z-index: 1;
}

.login-width {
    @media only screen and (max-width: 390px) {
        min-height: '100vw'
    }
}





.border-poup-sweetalert{
    /* font-family: 'Montserrat', sans-serif !important;  */
    border-radius: 1.5rem !important;
    width: 35% !important;
    padding: 3rem  !important;
}

.border-poup-sweetalert-content{
    /* font-family: 'Montserrat', sans-serif !important;  */
    border-radius: 1.5rem !important;
    width: 35% !important;
    padding: 1rem 3rem 4rem 3rem !important;
}

.border-poup-sweetalert-save-confirm{
    /* font-family: 'Montserrat', sans-serif !important;  */
    border-radius: 1.5rem !important;
    width: 35% !important;
    padding: 2rem  !important;
}

.title-sweet{

    color:#337EAB;
    font-size: 24px !important;
    padding-top: 3rem !important; 
    font-weight: 600 !important;

}

.title-sweet-login{
    color:#337EAB;
    font-size: 24px !important;
    padding-bottom: 3rem !important;
    padding-top: 1rem !important;
    font-weight: 600 !important;
}

.subtitle-sweet{
    color:#005e96 !important; 
    font-size:20px !important;
    font-weight: 500 !important;
}

.img-sweet{
    width: 6rem !important;
}

.img-sweet-2{
    width: 4rem !important;
}

.title-sweet-consent{

    color:#337EAB !important;
    font-size: 24px !important;
    padding-top: 1rem !important;
    font-weight: 600 !important;
    margin-bottom: 0.5rem !important;

}

.subtitle-sweet-consent{
    font-size: 20px !important;
    font-weight: 500 !important;
    line-height: 24.38px !important;
    margin-bottom: 2rem !important;
}

.button-consent{
    margin-top: 0 !important;
    border-radius: 0 !important;
    background: #005E96 !important;
    width: 80% !important;
    font-size: 16px !important;
    position: absolute !important;
    
}

.button-cliente-confirm{

    width: 200px !important;
    background: #005E96 !important;
    font-size: 14px !important;
}

.button-cliente-cancel{

    width: 200px !important;
    background: #C8C8C8 !important;
    font-size: 14px !important;
}

.button-consent,.button-cliente-cancel,.button-cliente-confirm:focus{
    box-shadow: 0 0 0 0rem rgba(255, 255, 255, 0) !important;
}

@media (max-width: 915px) {
    .img-sweet{
        width: 4rem !important;
    }

    .img-sweet-2{
        padding-top: 2rem !important;
    }

    .title-sweet{
        padding-top: 0 !important; 
        font-size: 18px !important;
    }

    .title-sweet-login{
        font-size: 18px !important;
        padding-bottom: 0 !important;
    }

    .title-sweet-consent{
        font-size: 18px !important;
    }

    .subtitle-sweet-consent{
        font-size: 14px !important;
    }

    .subtitle-sweet{
        font-size:14px !important;
    }
    
    .border-poup-sweetalert{
        width: 70% !important;
        padding: 0 !important;
        padding-bottom: 2.3rem !important;
    }

    .border-poup-sweetalert-content{
        width: 80% !important;
        padding: 0 !important;
        padding-bottom: 2.3rem !important;
    }

    .border-poup-sweetalert-save-confirm{
        width: 90% !important;
        padding: 0 !important;
        padding-bottom: 2.3rem !important;
    }

    .button-consent{
        font-size: 12.5px !important;
    }

    .button-cliente-confirm{
        width: 95px !important;
        font-size: 11px !important;
    }
    
    .button-cliente-cancel{
        width: 95px !important;
        font-size: 11px !important;
    }
}
.dropdown-toggle::after {
    display: none !important; 
  }